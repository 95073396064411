
export const InputErrors = {
  isRequired: 'Поле обязательно для заполнения',
  minLength: (limit: number) => `Минимум ${limit} символов`,
}

export const MY_COLLECTION_REFRESH_INTERVAL = 5000
export const qrCodeLocalStorageKey = 'qr-code'
export const LS_TOKENS_KEY = 'tokens'

export enum FormErrors {
  EnterEmail = 'Введите email',
  EnterPassword = 'Введите пароль',
  EnterConfirm = 'Подтвердите пароль',
  EmailIsIncorrect = 'Неверный формат email',
  PasswordsNotMatch = 'Пароли не совпадают',
  PasswordIsNotValid = 'Пароль не соответствует требованиям',
}

export enum ResetPasswordErrors {
  EnterOldPassword = 'Введите старый пароль',
  EnterNewPassword = 'Введите новый пароль',
  EnterConfirmPassword = 'Подтвердите новый пароль',
}

export const enableDate = '2023-12-06T10:43:00'