export default {
  colors: {
    black: '#0C101C',
    primary: 'rgba(255, 79, 18, 1)',
    primaryTransparent05: 'rgba(255, 79, 18, 0.5)',
    primaryTransparent02: 'rgba(255, 79, 18, 0.2)',
    secondaryBlack: '#151924',
    red: 'rgba(240, 33, 43, 1)',
  },
  headerHeight: 96,
  mobileHeaderHeight: 80,
}

const sizes = {
  mobile: '320px',
  tabletS: '768px',
  tabletL: '1024px',
}

export const devices = {
  mobile: `(max-width: ${sizes.tabletS}) and (min-width: ${sizes.mobile})`,
  tablet: `(max-width: ${sizes.tabletL}) and (min-width: ${sizes.tabletS})`,
  tabletAndMobile: `(max-width: ${sizes.tabletL})`,
}

export const BodyScrollController = {
  noScroll: () => {
    document.body.classList.add('no-scroll')
    // for safari
    document.getElementsByTagName('html')[0].classList.add('no-scroll')
  },
  scroll: () => {
    document.body.classList.remove('no-scroll')
    document.getElementsByTagName('html')[0].classList.remove('no-scroll')
  },
}