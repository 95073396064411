/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { devices } from './common/theme'
import { isSafari } from 'react-device-detect'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { blink, Plug } from './Card'

type VideoContainerProps = {
  src: string,
} & React.ComponentPropsWithoutRef<'div'>

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;

  video, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const VideoContainer = (props: VideoContainerProps) => {
  const [isLoading, setLoading] = useState(true)

  return <LazyLoadComponent>
    {isLoading && <Plug />}
    <ContentWrapper hidden={isLoading}>
      {isSafari ?
        <img src={props.src} onLoad={() => setLoading(false)} />
        :
        <video
          preload='auto'
          autoPlay
          muted
          loop
          playsInline={true}
          onLoadedData={() => setLoading(false)}
        >
          <source src={props.src} type="video/mp4" />
        </video>
      }
    </ContentWrapper>
  </LazyLoadComponent>
}