import React from 'react'
import styled from 'styled-components'

type TagsProps = {
  options: Record<string, boolean>,
  onChange: (tag: string) => Promise<void>,
  multiple?: boolean,
}

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
  gap: .5rem;
`

const Li = styled.li`
  display: flex;
`
const Label = styled.label<{selected?: boolean}>`
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 6px 15px;
  border-radius: 2px;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  
  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${({ selected, theme }) => selected && `
    background-color: ${theme.colors.primaryTransparent02};
    color: ${theme.colors.primary};
    :hover {
      background-color: ${theme.colors.primaryTransparent05};
    }
  `}
`

const Input = styled.input`
  display: none;
`

export const Tags = (props: TagsProps) => {
  return <Ul>
    {Object.entries(props.options).map(([tag, state], i) => (
      <Li key={i}>
        <Label selected={state}>
          <Input type='checkbox' name={`tag-${i}`} value={tag} checked={state} onChange={() => props.onChange(tag) } />
          {tag}
        </Label>
      </Li>
    ))}
  </Ul>
}