/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { GoBackButton, PrimaryButton } from '../../../../components/Button'
import { HorisontalDivider } from '../../../../components/Divider'
import useStores from '../../../../hooks/useStores'
import { CollectionItemFullInfo, LocationParams } from '../../../../types'
import { VideoContainer } from '../../../../components/VideoContainer'
import { devices } from '../../../../components/common/theme'
import { Plug } from '../../../../components/Card'
import { Block } from '../../../../components/Block'
import routes from '../../../../routes'
import { qrCodeLocalStorageKey } from '../../../../constants'
import ModalService from '../../../../components/modal/modal-service'
import { Success } from '../../modals/SuccessModal'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { Notification } from '../../../../components/Notifications'
import { observer } from 'mobx-react'
import { useIsBefore } from '../../../../hooks/useIsBefore'
import dayjs from 'dayjs'

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: 100px;

  @media ${devices.tabletAndMobile} {
    width: 100%;
  }
`

const Inner = styled.div`
  padding-top: 124px;
  display: flex;
  justify-content: space-between;

  @media ${devices.tabletAndMobile} {
    padding-top: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const VideoWrapper = styled.div`
  width: 450px;
  height: auto;
  overflow: hidden;
  border: 1px solid rgba(180, 180, 180, 1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;

  @media ${devices.tabletAndMobile} {
    width: 100%;
    height: auto;
    max-width: 800px;
  }
`

const InfoBlock = styled.div`
  width: 35%;
  margin: 0 auto;
  word-wrap: break-word;

  @media ${devices.tabletAndMobile} {
    margin-top: 30px;
    width: 95%;
    height: auto;
    max-width: 800px;
  }
`

const ItemTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
`

const Info = styled.div`
  margin: 25px 0;

  h4 {
    opacity: 60%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 16px;
    margin: 0;
  }
`

const Price = styled.p`
  font-size: 24px;
`
const ItemFooter = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OwnerBlock = styled.div`
  width: 250px;
  background-color: ${props => props.theme.colors.primary};
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  margin: 30px 0;
`

const InfoLink = styled.a`
  color: ${props => props.theme.colors.primary};

  :hover {
    text-decoration: underline;
  }
`

const EnableInfo = styled.a`
  color: ${props => props.theme.colors.primary};
  margin-left: 15px;
`

export const CollectionItem = observer(() => {
  const { api, keysStore, dataStore, authStore } = useStores()
  const tokenId = useParams().tokenId!
  const [itemData, setItemData] = useState<CollectionItemFullInfo>()
  const location = useLocation() as LocationParams<{ code: string }>
  const { state } = location
  const navigate = useNavigate()
  const isEnabled = useIsBefore(new Date(dataStore.appConfig.enableDate))
  const enableDate = dayjs(dataStore.appConfig.enableDate).format('DD.MM.YY в HH:mm')

  useEffect(() => {
    if (state?.code && !itemData?.owner) {
      localStorage.setItem(qrCodeLocalStorageKey, state.code)
    }
  }, [])

  useEffect(() => {
    const getTokenInfo = async () => {
      const [data, tokenInfo] = await Promise.all([api.getToken(tokenId), api.getTokenInfo(tokenId)])
      setItemData({ ...tokenInfo, ...data, videoUrl: data.medium })
    }

    getTokenInfo()
  }, [])

  const claimToken = async (code: string) => {
    try {
      await api.claimToken(code, keysStore.address)

      ModalService.open(({ close }) => (
        <Success
          text='Запрос на получение NFT одобрен. Его можно будет найти в вашей коллекции через несколько минут'
          close={close}
          buttonsBlock={<PrimaryButton onClick={() => navigate(`/${routes.myCollection}`)}>Перейти в мою коллекцию</PrimaryButton>}
        />
      ))
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        toast(<Notification isError title={'Ошибка'} message={'Промокод не найден'} />, {
          hideProgressBar: true,
        })
      }
    }
  }

  const onClaimClick = async (code: string) => {
    if (authStore.isLoggedIn) {
      await claimToken(code)
      return
    }
    navigate(`/${routes.signIn}`)
  }

  if (itemData) {
    return <Wrapper>
      <GoBackButton />
      <Inner>
        <VideoWrapper>
          <VideoContainer src={itemData.videoUrl} />
        </VideoWrapper>
        <InfoBlock>
          <ItemTitle>{itemData.name}</ItemTitle>
          {
            keysStore?.address === itemData.owner &&
              <OwnerBlock>Вы являетесь владельцем</OwnerBlock>
          }
          <Info>
            <h4>Коллекция</h4>
            <p>{itemData.collection.name}</p>
          </Info>
          <Info>
            <h4>Об NFT</h4>
            <p>{itemData.description}</p>
          </Info>
          <Info>
            <h4>Описание коллекции</h4>
            <p>{itemData.collection.description}</p>
          </Info>
          {!!itemData.tags.length && <Info>
            <h4>Категории</h4>
            {itemData.tags.map(el => (<p key={el.id}>{el.name}</p>))}
          </Info>
          }
          {keysStore?.address === itemData.owner &&
            <Info>
              <InfoLink
                target='_blank'
                href={`${dataStore.appConfig.clientAddress}/explorer/transactions/filters?txsTypes=3%2C4%2C5%2C6%2C8%2C9%2C11%2C14&query=${tokenId}&queryGroup=in_token_name_or_id`}
              >
                Информация о токене
              </InfoLink>
            </Info>
          }
          {state?.code && !itemData.owner &&
          <>
            <HorisontalDivider />
            <ItemFooter>
              <PrimaryButton
                onClick={() => onClaimClick(state.code)}
                disabled={!isEnabled}
              >
                Получить
              </PrimaryButton>
              {isEnabled !== undefined && !isEnabled && <EnableInfo>Получение станет доступно {enableDate}</EnableInfo>}
              {!authStore.isLoggedIn &&
              <EnableInfo>
                  Чтобы получить Ваш персональный NFT-токен необходимо зарегестрироваться на сайте.
                  Перейдите в меню в правом верхнем углу и выберете «Вход и регистрация»
              </EnableInfo>
              }
            </ItemFooter>
          </>
          }
          {/* <HorisontalDivider />
          <ItemFooter>
            <Price>{itemData.price} &#x20bd;</Price>
            <PrimaryButton onClick={() => addBuyModal(itemData)}>Купить</PrimaryButton>
          </ItemFooter> */}
        </InfoBlock>
      </Inner>
    </Wrapper>
  }

  return <Wrapper>
    <GoBackButton />
    <Inner>
      <Plug height={450} width={450} mobile={'max-height: 300px; max-width: 300px'} />
      <InfoBlock>
        <Plug height={48} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={48} width={200} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={18} width={148} />
        <Plug height={18} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={18} width={148} />
        <Plug height={18} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={18} width={148} />
        <Plug height={18} />
      </InfoBlock>
    </Inner>
  </Wrapper>
})
