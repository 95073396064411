import React from 'react'
import { MobXProviderContext } from 'mobx-react'
import AuthStore from '../stores/AuthStore'
import KeysStore from '../stores/KeysStore'
import { API } from '../api'
import DataStore from '../stores/DataStore'

export type AppContext = {
  api: API,
  authStore: AuthStore,
  keysStore: KeysStore,
  dataStore: DataStore,
}

export default () => {
  return React.useContext(MobXProviderContext) as AppContext
}
