import React from 'react'
import { BuyPage } from './components/buy/BuyPage'
import { Collection } from './components/buy/Collection'
import { CollectionItem } from './components/buy/CollectionItem'
import { Create } from './components/Create'
import { HowItWorks } from './components/HowItWorks'
import { MyCollection } from './components/MyCollection'
import { QrCode } from './components/QrCode'
import appRoutes from '../../routes'
import useStores from '../../hooks/useStores'
import { Navigate } from 'react-router-dom'
import { RouteProps } from '../../types'
import { RedeemRedirect } from './components/RedeemRedirect'

const Logout = () => {
  const { authStore } = useStores()
  authStore.logout()
  return <Navigate replace to={appRoutes.signIn} />
}

const Redirect = () => (<Navigate replace to={appRoutes.collections} />)

export const routes: RouteProps[] = [
  {
    path: '/',
    element: BuyPage,
  },
  {
    path: '*',
    element: Redirect,
  },
  {
    path: `/${appRoutes.collections}/:collectionId`,
    element: Collection,
  },
  {
    path: `/${appRoutes.tokens}/:tokenId`,
    element: CollectionItem,
  },
  {
    path: `/${appRoutes.create}`,
    element: Create,
  },
  {
    path: `/${appRoutes.myCollection}`,
    element: MyCollection,
    isPrivate: true,
  },
  {
    path: `/${appRoutes.howItWorks}`,
    element: HowItWorks,
  },
  {
    path: `/${appRoutes.qrCode}`,
    element: QrCode,
    isPrivate: true,
  },
  {
    path: `/${appRoutes.logout}`,
    element: Logout,
  },
  {
    path: '/redeem/:code',
    element: RedeemRedirect,
  },
]
