import React from 'react'
import appRoutes from '../../routes'
import { SignIn } from './components/SignIn'
import { SignUp, ConfirmRegistration } from './components/SignUp'
import { PasswordRecovery } from './components/PasswordRecovery'
import { Navigate } from 'react-router-dom'
import { ResetPassword } from './ResetPassword'

export type PrivateRoutesProp = {
  element: any,
  path: string,
  withTabs?: boolean,
  title?: string,
}

const Redirect = () => (<Navigate replace to={appRoutes.signIn} />)

export const routes: PrivateRoutesProp[] = [
  {
    path: `/${appRoutes.signIn}`,
    element: SignIn,
    withTabs: true,
  },
  {
    path: '*',
    element: Redirect,
  },
  {
    path: `/${appRoutes.signUp}`,
    element: SignUp,
    withTabs: true,
  },
  {
    path: `/${appRoutes.confirm}`,
    element: ConfirmRegistration,
    title: 'Завершите регистрацию',
  },
  {
    path: `/${appRoutes.recover}`,
    element: PasswordRecovery,
    title: 'Восстановление пароля',
  },
  {
    path: '/auth/activate',
    element: SignIn,
    withTabs: true,
  },
  {
    path: '/auth/restore/reset-password',
    element: ResetPassword,
    title: 'Изменение пароля',
  },
]