/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import useStores from '../../../hooks/useStores'
import routes from '../../../routes'
import { toast } from 'react-toastify'
import { Notification } from '../../../components/Notifications'

export const RedeemRedirect = () => {
  const { api } = useStores()
  const { code } = useParams()
  const [tokenId, setTokenId] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchTokenIdByCode = async () => {
      try {
        if (code) {
          const tokenId = await api.getTokenIdByCode(code)
          setTokenId(tokenId)
          navigate(`/${routes.tokens}/${ tokenId }`, { state: { code }, replace: true })
        }
      } catch (e) {
        console.error(e)
        toast(<Notification message='NFT по заданному коду не найдено' />)
        navigate(`/${routes.root}`)
      }
    }
    fetchTokenIdByCode()
  }, [])

  return null
}