import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import searchImg from '../resources/images/search.png'
import { Tags } from './Tags'

type SearchInputProps = {
  placeholder: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
} & React.ComponentPropsWithoutRef<'input'>

type SearchInputWithTagsProps = {
  tags: string[],
  onChangeWithTags?: (input: string) => Promise<void>,
  multiple?: boolean,
} & SearchInputProps

const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 1.3rem;
  padding-left: 5rem;
`

const SearchImg = styled.div`
  background-image: url(${searchImg});
  background-size: 28px;
  position: absolute;
  top: 0rem;
  left: 1.5rem;
  height: 27px;
  width: 28px;
  background-size: cover;
`

const Search = styled.input`
  margin: 0 auto;
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 18px;

  ::focus {
    outline: none;
    border: none;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 19px;
  }
`

export const SearchInput = (props: SearchInputProps) => {
  return <Wrapper>
    <SearchImg />
    <Search type='text' placeholder={props.placeholder} onChange={props.onChange} value={props.value} />
  </Wrapper>
}

export const SearchInputWithTags = (props: SearchInputWithTagsProps) => {
  const [input, setInput] = useState('')
  const [tags, setTags] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (props.tags.length) {
      setTags(props.tags.reduce((acc, el) => (
        { ...acc, [el]: false }), {} as Record<string, boolean>))
    }
  }, [props.tags])

  const updateTagState = (tags: Record<string, boolean>, value: string) => {
    return Object.entries(tags).reduce((acc, [tag, state]) => {
      if (props.multiple) {
        return {
          ...acc,
          [tag]: tag === value ? !state : state,
        }
      }
      return {
        ...acc,
        [tag]: tag === value && !state,
      }
    }, {} as Record<string, boolean>)
  }

  const handleChange = async (value: string) => {
    const newTagsState = updateTagState(tags, value)
    setTags(newTagsState)
    const selectedTags = Object.entries(newTagsState).filter(([, state]) => state === true).map(([tag]) => tag)
    const [selectedTag] = selectedTags
    setInput(selectedTag || '')
    await props.onChangeWithTags?.(selectedTag)
  }

  const onChangeInput = async (e: any) => {
    const input = e.target.value
    setInput(input)
    await props.onChangeWithTags?.(input)
    setTags(prev => updateTagState(prev, input))
  }

  return <>
    <SearchInput placeholder={props.placeholder} onChange={onChangeInput} value={input} />
    <Tags options={tags} onChange={handleChange} multiple={props.multiple} />
  </>
}
