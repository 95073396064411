/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import useStores from './useStores'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const useIsBefore = (date: Date) => {
  const { api, authStore } = useStores()
  const utcTimeRef = useRef<Dayjs>()
  const [currentUtcTime, setCurrentUtcTime] = useState<Dayjs>()
  const [isBefore, setIsBefore] = useState<boolean>()
  const intervalId = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    const fetchUtcTime = async () => {
      try {
        const utcTime = await api.getNodeTime()
        const date = dayjs(utcTime).utc()
        utcTimeRef.current = date
        setCurrentUtcTime(date)
      } catch (e) {
        console.error(e)
      }
    }
    if (authStore.isLoggedIn) {
      fetchUtcTime()
    }
  }, [authStore.isLoggedIn])

  useEffect(() => {
    if (utcTimeRef.current) {
      intervalId.current = setInterval(() => {
        setCurrentUtcTime(prev => prev?.add(5000, 'milliseconds'))
      }, 5000)
    }
  }, [utcTimeRef.current])

  useEffect(() => {
    if (currentUtcTime) {
      const isBefore = dayjs(date).utc().isBefore(currentUtcTime)
      setIsBefore(isBefore)
      if (isBefore) {
        clearInterval(intervalId.current)
      }
    }
  }, [currentUtcTime])

  return isBefore
}