import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import theme from './components/common/theme'
import StyleGlobal from './resources/styles/styles-global'
import { Content } from './Content'
import { ScrollToTop } from './ScrollToTop'
import { API } from './api'
import AuthStore from './stores/AuthStore'
import KeysStore from './stores/KeysStore'
import DataStore from './stores/DataStore'

const api = new API()
const keysStore = new KeysStore(api)
const authStore = new AuthStore(api, keysStore)
const dataStore = new DataStore(api)

const stores = {
  api,
  authStore,
  keysStore,
  dataStore,
}

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Provider {...stores}>
        <ThemeProvider theme={theme}>
          <StyleGlobal />
          <Content />
        </ThemeProvider>
      </Provider>
    </Router>
  )
}

export default App
