import { makeAutoObservable } from 'mobx'
import decodeJWT from 'jwt-decode'
import { API, ITokenPair } from '../api'
import KeysStore from './KeysStore'
import { qrCodeLocalStorageKey } from '../constants'

const tokensLocalStorageKey = 'tokenPair'

export default class AuthStore {
  api: API
  keysStore: KeysStore

  isLoggedIn = false
  isWalletAvailable = false
  walletState = {}

  id = ''
  // roles: string[] = []

  constructor(api: API, keysStore: KeysStore) {
    this.api = api
    this.keysStore = keysStore
    this.checkLoggedId()
    makeAutoObservable(this)
  }

  async checkLoggedId() {
    const tokens = this.readTokenPair()
    if (tokens) {
      const {
        axios: refresherAxios,
      } = this.createRefresherAxios(tokens as ITokenPair, () => {
        this.logout()
      })
      this.api.setupApi(refresherAxios)
      this.setLoggedIn(true)
      await this.keysStore.init()
    }
  }

  // async loadUserProfile() {
  //   const profile = await this.api.getUserProfile()

  //   this.roles = profile.roles
  // }

  createRefresherAxios(tokenPair: ITokenPair, onRefreshFailed: () => void) {
    return this.api.createAxiosWithRefresher(tokenPair, onRefreshFailed)
  }

  signIn(username: string, password: string): Promise<ITokenPair> {
    return this.api.signIn(username, password)
  }

  signUp(username: string, password: string): Promise<ITokenPair> {
    return this.api.signUp(username, password)
  }

  logout(): void {
    this.deleteTokenPair()
    this.setLoggedIn(false)
    localStorage.removeItem(qrCodeLocalStorageKey)
  }

  loginWithTokenPair(tokenPair: ITokenPair): void {
    const data = decodeJWT(tokenPair.access_token)
    console.log(data)
  }

  setLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn
  }

  writeTokenPair(tokenPair: ITokenPair): void {
    window.localStorage.setItem(tokensLocalStorageKey, JSON.stringify(tokenPair))
  }

  deleteTokenPair(): void {
    window.localStorage.removeItem(tokensLocalStorageKey)
  }

  readTokenPair(): ITokenPair | null {
    const data = window.localStorage.getItem(tokensLocalStorageKey)
    if (data) {
      try {
        return JSON.parse(data)
      } catch (e) {
        return null
      }
    }
    return null
  }
}
