/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Block } from '../../../../components/Block'
import { CenteredButtonsWrapper, GoUpButton, PrimaryButton, SecondaryButton } from '../../../../components/Button'
import { DefaultCard, CardTitle, CardInfo, CollectionCardsWrapper, CardInner, CardsLoader, blink, CardLoader } from '../../../../components/Card'
import { SearchInput, SearchInputWithTags } from '../../../../components/Search'
import { Collection, CollectionItem, ITag } from '../../../../types'
import routes from '../../../../routes'
import { useNavigate, useParams } from 'react-router-dom'
import ModalService from '../../../../components/modal/modal-service'
import { BuyModal } from '../../modals/BuyModal'
import { kitcut } from '../../../../utils'
import useStores from '../../../../hooks/useStores'
import { devices } from '../../../../components/common/theme'
import banner from '../../../../resources/images/banner.png'
import bannerLogo from '../../../../resources/images/logo.png'
import throttle from 'lodash.throttle'
import { useDebouncedCallback } from '../../../../hooks/useDebounce'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

export const addBuyModal = (itemData: CollectionItem, hideButton?: boolean) => {
  ModalService.open(({ close }) => <BuyModal {...itemData} close={close} hideButton={hideButton} />)
}

const SIZE = 9

const BuyPageWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const BannerImageWrapper = styled.div`
  object-position: center;

  img {
    width: 100%;
    height: auto;
  }

  @media ${devices.mobile} {
    height: 300px;
    img {
      display: none
    }
  }
`

const BannerWrapper = styled.div`
  position: relative;
`

const BannerInner = styled.div`
  position: absolute;
  top: 5%;
  /* transform: translateY(-50%); */
  width: 50%;

  @media ${devices.mobile} {
    width: 100%;
  }
`

const BannerTitle = styled.h1`
  margin: 0 auto;
  font-size: 42px;

  @media (max-width: 1220px) {
    font-size: 32px;
  }

  @media ${devices.tablet} {
    font-size: 28px;
  }

  @media ${devices.mobile} {
    text-align: center;
  }
`

const BannerButtons = styled.div`
  margin: 40px auto;

  @media ${devices.mobile} and (orientation: landscape)  {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  } 

  @media ${devices.mobile} and (orientation: portrait) {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  } 
`

const CardLogoWrapper = styled.div<{ imgUrl: string }>`
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
  width: 96px;
  height: 91px;
  position: absolute;
  left: -10%;
  top: -46%;
  z-index: 2;
  transform: scale(0.8);

  @media ${devices.mobile} {
    left: -2%;
    top: -35%;
    width: 50px;
    height: 50px;
  } 
`

const LoadMoreButton = styled(SecondaryButton)<{fetching: boolean}>`
  ${({ fetching }) => fetching && css`
      animation: ${blink} 1s ease-in-out infinite;
      background-color: rgba(40, 47, 66, 1);
  `}
`

const BannerLogoWrapper = styled.div`
  position: absolute;
  top: 5%;
  right: 10%;

  img {
    height: 55px;
    width: auto;
  }

  @media ${devices.mobile} {
    img {
      display: none
    }
  }
`

const Banner = () => {
  const { authStore } = useStores()
  const { isLoggedIn } = authStore
  const navigate = useNavigate()
  return <BannerWrapper>
    <BannerInner>
      <BannerTitle>Познакомьтесь с NFT-коллекцией виртуальной галереи искусств Rostelecom Tech Day</BannerTitle>
      <BannerButtons>
        {isLoggedIn ? <PrimaryButton onClick={() => navigate(routes.qrCode)}>Получить NFT</PrimaryButton> :
          <PrimaryButton onClick={() => navigate(routes.signIn)}>Регистрация</PrimaryButton>
        }
      </BannerButtons>
    </BannerInner>
    <BannerLogoWrapper>
      <img src={bannerLogo} />
    </BannerLogoWrapper>
    <BannerImageWrapper>
      <img src={banner} />
    </BannerImageWrapper>
  </BannerWrapper>
}

const CollectionItemCard = (props: CollectionItem) => {
  const navigate = useNavigate()
  const { api } = useStores()

  const onLinkClick = () => {
    navigate(`${routes.collections}/${props.id}`)
  }

  const onClickView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, itemData: CollectionItem) => {
    e.stopPropagation()
    navigate(`/${routes.tokens}/${itemData.id}`)
  }

  return <>
    <DefaultCard
      id={props.id}
      key={props.id}
      imgUrl={props.videoUrl}
      isLandscapeOriented
    >
      {/* <Link onClick={onLinkClick}>{props.author}</Link> */}
      <CardInner>
        {/* <CardLogoWrapper imgUrl={props.collection.collectionIcon} /> */}
        <CardTitle>{props.name}</CardTitle>
        {props.description && <CardInfo>{kitcut(props.description)}</CardInfo>}
        {/* <SecondaryButton isSmall disabled={true}>Купить</SecondaryButton> */}
        <PrimaryButton isWide onClick={(e) => onClickView(e, props)}>Посмотреть</PrimaryButton>
      </CardInner>
    </DefaultCard>
  </>
}

export const BuyPage = observer(() => {
  const tokenId = useParams().tokenId!
  const { dataStore } = useStores()
  const { tokens, tags, collection, count: totalCount, fetching } = dataStore
  const [showUpButton, setShowUpButton] = useState(false)

  useEffect(() => {
    if (!tags.length) {
      dataStore.fetchTags()
    }
    if (!collection) {
      dataStore.fetchCollection()
    }
    if (!tokens.length) {
      dataStore.fetchTokens()
    }
  }, [])

  const loadMoreHandler = () => {
    dataStore.fetchTokens()
  }

  const handleScroll = () => {
    setShowUpButton(window.pageYOffset > 100)
  }

  const throttledHandleScroll = throttle(handleScroll, 1000)

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  const onChangeWithTags = async (input: string) => {
    dataStore.setSearchValue(input)
    await dataStore.fetchTokens({ clearTokens: true })
  }

  const debounsedOnChangeWithTags = useDebouncedCallback(onChangeWithTags)

  return <>
    <BuyPageWrapper>
      <Banner />
      <Block marginTop={50} mobileMarginTop={50} />
      <SearchInputWithTags
        placeholder='Найдите NFT'
        onChangeWithTags={debounsedOnChangeWithTags}
        tags={tags}
        multiple={false}
      />
      <Block marginTop={50} mobileMarginTop={50} />
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 600: 2, 900: 3 }}
      >
        <Masonry gutter={'20px'}>
          {fetching ?
            new Array(6).fill('').map((el, i) => (
              <CardLoader key={i} />
            ))
            :
            tokens.map((token, i) => (
              <CollectionItemCard
                key={i}
                {...token}
              />),
            )
          }
        </Masonry>
      </ResponsiveMasonry>

      <Block marginTop={50} />
      {tokens.length < totalCount &&
        <CenteredButtonsWrapper>
          <LoadMoreButton
            isSmall
            onClick={loadMoreHandler}
            fetching={fetching}
            disabled={fetching}
          >
           Показать больше
          </LoadMoreButton>
        </CenteredButtonsWrapper>
      }
      <Block marginTop={50} />
      <GoUpButton className={showUpButton ? 'show' : 'hide'} />
    </BuyPageWrapper>
  </>
})
