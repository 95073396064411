import { useCallback, useRef } from 'react'

export const useDebouncedCallback = (func: (...args: any[]) => Promise<void>, delay = 500) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  // eslint-disable-next-line @typescript-eslint/require-await, require-await
  return useCallback(async (...args: any[]) => {
    const later = async () => {
      clearTimeout(timeout.current)
      await func(...args)
    }

    clearTimeout(timeout.current)
    if (timeout) {
      timeout.current = setTimeout(later, delay)
    }
  },
  [func, delay],
  )
}