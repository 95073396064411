/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren, useEffect } from 'react'
import useStores from '../hooks/useStores'
import { Navigate } from 'react-router-dom'
import routes from '../routes'
import { observer } from 'mobx-react'

export const ProtectedRoute = observer(({ children }: PropsWithChildren) => {
  const { authStore } = useStores()
  const isLoggedIn = authStore.isLoggedIn

  return isLoggedIn ? <>{children}</> : <Navigate replace to={routes.signIn} />
})