import { makeAutoObservable, runInAction } from 'mobx'
import { API } from '../api'
import { Collection, CollectionItem, ITag } from '../types'
import axios from 'axios'

const SIZE = 9

const emptyAppConfig = { clientAddress: '', enableDate: '' }

export default class DataStore {
  api: API

  appConfig = emptyAppConfig

  queries = { page: 1, size: SIZE }
  tokens: CollectionItem[] = []
  count = 0
  collection: Collection | undefined
  tags: string[] = []
  fetching = false
  searchValue = ''

  constructor(api: API) {
    this.api = api

    makeAutoObservable(this)
    this.loadInitialConfig()
  }

  async loadInitialConfig() {
    try {
      const start = Date.now()
      const { data }: { data: typeof emptyAppConfig } = await axios.get(`/app.config.json?t=${Date.now()}`)
      console.log('app.config.json loaded:', data, ', time elapsed:', Date.now() - start, 'ms')
      runInAction(() => {
        this.appConfig = data
      })
    } catch (e: any) {
      console.error('Cannot get app config:', e.message)
    }
  }

  async fetchTags() {
    try {
      const tags: ITag[] = await this.api.getTags()
      const uniqTags = new Set(tags.map(el => el.name))
      runInAction(() => {
        this.tags = Array.from(uniqTags)
      })
    } catch (e) {
      console.error(e)
    }
  }

  async fetchCollection() {
    try {
      const collection = await this.api.getCollections()
      runInAction(() => {
        this.collection = collection[0]
      })
    } catch (e) {
      console.error(e)
    }
  }

  setSearchValue(value: string) {
    this.searchValue = value
  }

  async fetchTokens({ clearTokens }: { clearTokens?: boolean } = {}) {
    this.fetching = true
    try {
      if (clearTokens) {
        runInAction(() => {
          this.queries.page = 1
        })
      }
      const { data, count } = await this.api.getTokens({ ...this.queries, nameLike: this.searchValue })
      const tokensToSet = data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          videoUrl: item.small,
          description: item.description,
          collection: this.collection,
          tags: item.tags,
        }
      })
      runInAction(() => {
        if (clearTokens) {
          this.tokens = tokensToSet
        } else {
          this.tokens = [...this.tokens, ...tokensToSet]
        }
        this.count = count
        this.queries.page = this.queries.page + 1
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.fetching = false
    }
  }
}
